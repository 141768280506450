@import 'variables';

$table-bg: white;
$table-header-color: #b9b9b9;
$table-text-color: black;
$table-border-color: #999999;
$table-stripe-color: #e6e6e6;
$table-selected-row-bg: #007ad9;

.table {
  background-color: $table-bg;
  border-collapse: collapse;
  border: 1px solid $table-border-color;
  color: $table-text-color;
  margin-bottom: $grid-gutter;
  width: 100%;

  th,
  td {
    padding: calc(#{$grid-gutter-quarter} / 2) $grid-gutter-quarter;
    vertical-align: top;
    border: 0;
    margin: 0;

    &:not(:last-child) {
      border-right: 1px solid $table-border-color;
    }
  }

  thead {
    th,
    td {
      background-color: $table-header-color;
      border-bottom: 1px solid $table-border-color;
      font-weight: bold;
      text-align: left;
      vertical-align: bottom;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: $table-stripe-color;
    }
  }

  tbody + tbody {
    border-top: 1px solid $table-border-color;
  }
}

.p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}

.p-treetable-scrollable-body {
  overflow-y: scroll !important;
}

.chronos-pallet-table {
  background-color: #e6e6e6;
  padding: 0 15px 15px;
  font-family: $roboto-family;
  font-size: 16px;

  &.p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
    padding: 15px 10px;
    line-height: 20px;
    background: #e6e6e6;
    border: none;
  }

  &.p-datatable .p-datatable-tbody > tr > td {
    font-weight: 400;
    background: #ffffff;
    padding: 10px;
    border: none;
    border-bottom: 2px solid #e6e6e6;
  }

  &.p-datatable .p-datatable-scrollable-header {
    background-color: #e6e6e6;
  }
}

.p-datatable .p-datatable-tbody > tr.highlighted-row {
  background: $table-selected-row-bg;
  color: $gray-1;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr.highlighted-row:hover {
  background: $table-selected-row-bg;
  color: $gray-1;
}

// ******************************************
// TreeTable from PrimeNG
// ******************************************

.p-treetable {
  .p-treetable-thead > tr,
  .p-treetable-tbody > tr {
    height: 50px;

    th,
    td {
      border-left: 0;
      border-right: 0;
      color: $gray-12;
    }
  }

  .p-treetable-thead > tr {
    font-size: $font-size-md;

    th {
      font-weight: 600;
      background-color: $gray-1;
      border-top: 0;
    }
  }

  .p-treetable-tbody > tr {
    td {
      font-weight: $font-weight-regular;
    }

    &.first-level-row {
      background-color: $gray-1;
    }

    &.second-level-row {
      background-color: $gray-3;

      td {
        border-bottom: 1px solid $gray-1;
      }
    }

    &.third-level-row {
      background-color: $gray-4;

      td {
        border-bottom: 1px solid $gray-1;
      }
    }

    &.fourth-level-row {
      background-color: $gray-5;

      td {
        border-bottom: 1px solid $gray-1;
      }
    }

    &.dummy-article-row {
      background-color: $warning-3;

      td {
        border-bottom: 1px solid $gray-1;
      }
    }
  }
}

.traceability-tree-table {
  & tr {
    th {
      color: $gray-11 !important;
      font-weight: 700 !important;
    }
  }
}

.approve-report-tree-table {
  & .p-treetable-scrollable-view {
    overflow: hidden;
  }

  & .p-treetable-scrollable-header {
    overflow: visible;
  }

  & .p-treetable-scrollable-header-table {
    border-bottom: 2px solid #c8c8c8;
  }
}

.approve-report-table__order-row,
.traceability-table__order-row {
  & .p-treetable-toggler {
    margin: 0 !important;
  }
}

.registration-table > p-table > .p-datatable {
  .p-datatable-thead > tr,
  .p-datatable-tbody > tr {
    height: 50px !important;

    th,
    td {
      border-left: 0 !important;
      border-right: 0 !important;
      color: $gray-12 !important;
    }
  }

  .p-datatable-thead > tr {
    font-size: $font-size-md !important;

    th {
      font-weight: 600 !important;
      background-color: $gray-1 !important;
      border-top: 0 !important;
    }
  }

  .p-datatable-tbody > tr {
    td {
      font-weight: $font-weight-regular !important;
    }

    &.first-level-row {
      background-color: $gray-1 !important;
    }

    &.second-level-row {
      background-color: $gray-3 !important;

      td {
        border-bottom: 1px solid $gray-1 !important;
      }
    }

    &.third-level-row {
      background-color: $gray-4 !important;

      td {
        border-bottom: 1px solid $gray-1 !important;
      }
    }

    &.fourth-level-row {
      background-color: $gray-5 !important;

      td {
        border-bottom: 1px solid $gray-1 !important;
      }
    }
  }
}
