@import 'variables';

.p-tabview {
  & &-panels {
    background-color: transparent;
    border: none;
    border-top: 1px solid $default-input-border;
    padding: $grid-gutter-half 0;
  }

  & &-nav li.p-highlight &-nav-link {
    font-weight: $font-weight-regular;
  }
}

$tab-menu-border-color: #c8c8c8;
$tab-menu-padding: $default-padding * 2 $default-padding * 3;
$tab-menu-border-bottom: 1px solid $tab-menu-border-color;

.p-tabmenu {
  & &-nav {
    border-bottom: $tab-menu-border-bottom;
  }

  & &-nav .p-tabmenuitem .p-menuitem-link {
    font-weight: $font-weight-regular;
    padding: $tab-menu-padding;

    &:not(.p-disabled):focus {
      box-shadow: none;
    }
  }
}

.operation-support__sidebar {
  .p-tabmenu {
    width: 115%;
    &-nav {
      border: 0 none;
    }
    &-nav .p-tabmenuitem {
      background: #f5f5f5;
      padding: 20px;
      width: 87%;
      margin-right: 0px;
      margin-bottom: 15px;
      border: 1px solid $gray-5;
    }

    &-nav .p-highlight {
      background: #fff;
      width: 100%;
      box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
      border: 1px solid $gray-5;

      .p-menuitem-link-active {
        width: 87%;
      }
    }
  }
}
